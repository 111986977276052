import EventBus from '../EventBus';

const addNotification = (message, type, options) => {
    EventBus.$emit('AddNewNotification', message, type, options);
};

const addSuccesNotification = (message, options) => {
    addNotification(message, 'success', options);
};

const addInfoNotificationAsync = (message, timeout) => {
    return new Promise((resolve) => {
        addNotification(message, 'info', {
            timeout,
            cb: () => {
                return resolve();
            },
        });
    });
};

const addInfoNotification = (message, options) => {
    addNotification(message, 'info', options);
};

const addErrorNotification = (message, options) => {
    addNotification(message, 'error', options);
};

export {
    addNotification,
    addSuccesNotification,
    addInfoNotification,
    addErrorNotification,
    addInfoNotificationAsync,
};

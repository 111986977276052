'use strict';

import { AssetsResource } from './../../services/assets';

const state = {
    featuredAssets: [],
    popularAssets: [],
    newAssets: [],
    start: 0,
};

const getters = {
    featuredAssets: (state) => {
        return state.featuredAssets;
    },
    popularAssets: (state) => {
        return state.popularAssets;
    },
    newAssets: (state) => {
        return state.newAssets;
    },
    iOS: (state, getters, rootState) => {
        return rootState.settings.iOS;
    },
    mobile: (state, getters, rootState) => {
        return rootState.settings.mobile;
    },
};

const actions = {
    loadFeaturedAssets({ state, commit, getters }) {
        let now = new Date().getTime();
        if (now - state.start <= 1800000) {
            return;
        }

        commit('setStart', now);

        AssetsResource.get({
            featured: true,
            offset: 0,
            limit: 10,
            include_tags: true,
            content_type: 3,
            platform_compatibility: getters.mobile || getters.iOS ? 1 : 0,
        }).then((response) => {
            commit('setFeaturedAssets', response.body.rows);
        });
    },
    loadNewAssets({ commit, getters }) {
        let newAsset;

        AssetsResource.get({
            order_fields: ['released_at'],
            order_direction: ['desc'],
            offset: 0,
            limit: 10,
            content_type: 3,
            include_tags: true,
            platform_compatibility: getters.mobile || getters.iOS ? 1 : 0,
        })
            .then((response) => {
                newAsset = response.data.rows.filter((asset) => {
                    let assetDate = new Date(
                        Date.parse(asset.released_at) + 60 * 60 * 24 * 14 * 1000
                    );
                    return assetDate > new Date();
                });
                commit('setNewAssets', newAsset);
            })
            .catch((error) => {
                console.error(error);
                commit('setNewAssets', []);
            });
    },
    loadPopularAssets({ commit, getters }) {
        AssetsResource.get({
            order_fields: ['subscribers_count'],
            order_direction: ['desc'],
            offset: 0,
            limit: 10,
            include_tags: true,
            content_type: 3,
            platform_compatibility: getters.mobile || getters.iOS ? 1 : 0,
        }).then((response) => {
            const popularAssets = response.data.rows;
            commit('setPopularAssets', popularAssets);
            return popularAssets;
        });
    },
};

const mutations = {
    setFeaturedAssets(state, assets) {
        state.featuredAssets = assets;
    },
    setPopularAssets(state, assets) {
        state.popularAssets = assets;
    },
    setNewAssets(state, assets) {
        state.newAssets = assets;
    },
    setStart(state, start) {
        state.start = start;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

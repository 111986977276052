import Vue from './../init';

const uri = 'asset';

var customActions = {
    featured: { method: 'GET', url: uri + '/featured' },
    subscribe: { method: 'POST', url: uri + '/{id}/subscribe' },
    rate: { method: 'PUT', url: uri + '/{id}/rate' },
    report: { method: 'POST', url: uri + '/{id}/report' },
    updateScreenshots: { method: 'PUT', url: uri + '/{id}/screenshots' },
    updateThumbnail: { method: 'PUT', url: uri + '/{id}/thumbnail' },
};

export const AssetsResource = Vue.resource(uri + '{/id}', {}, customActions);

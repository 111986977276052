var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"find-tag__controls",class:[_vm.inputSize]},[_c('div',{staticClass:"find-tag__input input input--search input--rounded input--icon",class:[_vm.inputSize]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchString),expression:"searchString"}],staticClass:"new-tag js-input-field",attrs:{"placeholder":_vm.inputPlaceholder,"type":"text","maxlength":_vm.maxItemNameLength},domProps:{"value":(_vm.searchString)},on:{"keyup":function($event){return _vm.inputOnKey($event, _vm.searchString)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.hideDropdown()},"input":function($event){if($event.target.composing)return;_vm.searchString=$event.target.value}}}),_c('div',{staticClass:"dropdown-outer",class:{
        open: _vm.openDrodown,
        empty: _vm.items.length == 0 && _vm.allowCreate == false,
      }},[(_vm.items.length > 0)?_c('ul',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreItemsOnScroll),expression:"loadMoreItemsOnScroll"}],staticClass:"dropdown",attrs:{"infinite-scroll-disabled":"scrollDisabled","infinite-scroll-distance":"20"}},[_vm._l((_vm.items),function(item,i){return _c('li',{key:`selector_item_${i}`},[_c('a',{attrs:{"href":"#"},domProps:{"innerHTML":_vm._s(
              _vm.higlightItemName(
                item.name
                  ? item.name.replace('YlandsGenre_', '')
                  : item.ylands_username
              )
            )},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==40)return null;$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==38)return null;$event.preventDefault();$event.stopPropagation();return _vm.focusOnPrev($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.hideDropdown()}],"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==40)return null;$event.preventDefault();$event.stopPropagation();return _vm.focusOnNext($event)},"click":function($event){$event.preventDefault();return _vm.addNew(item)}}})])}),(_vm.loading)?_c('li',[_c('div',[_vm._v("Loading...")])]):_vm._e(),(
            _vm.items.length === 0 &&
            !_vm.loading &&
            !_vm.allowCreateItemMenuItemVisible &&
            !_vm.validateError
          )?_c('li',[_c('a',{staticClass:"disabled",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.hideDropdown()}}},[_c('BanIcon',{staticClass:"find-tag__icon-add icon-no-tags"}),_vm._v(" No match ")],1)]):_vm._e(),(_vm.allowCreate)?[(
              _vm.allowCreateItemMenuItemVisible &&
              !_vm.itemInSelectionByName(_vm.searchString) &&
              !_vm.validateError
            )?_c('li',[_c('a',{attrs:{"href":"#"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==40)return null;$event.preventDefault();$event.stopPropagation();return _vm.focusOnNext($event)},"keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==38)return null;$event.preventDefault();$event.stopPropagation();return _vm.focusOnPrev($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.hideDropdown()}],"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.createItem(_vm.searchString)}}},[_c('DocumentAddIcon',{staticClass:"find-tag__icon-add icon-add-tag"}),_vm._v(" Add new item ")],1)]):_vm._e(),(_vm.validateError)?_c('li',[_c('a',{staticClass:"disabled",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.hideDropdown()}}},[_c('BanIcon',{staticClass:"find-tag__icon-add icon-no-tags"}),_vm._v(" "+_vm._s(_vm.validateError)+" ")],1)]):_vm._e()]:_vm._e()],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
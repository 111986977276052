<template>
    <div
        class="dropdown"
        :class="[dropdownSize, { 'dropdown--search': searchSection }]"
        @click.stop
        @touchstart.stop
        @keydown.esc="hideDropdown"
    >
        <button
            ref="label"
            class="dropdown__label button"
            @click="toggleDropdown"
        >
            <SortedIcon
                fill="#0a294a"
                width="12"
                height="11"
                :class="{ rotated: isOpen }"
            />
            <div class="button__text">
                <span v-if="sortBy" class="button__text--top">Sort by</span>
                <span class="button__text--bottom">{{ dropdownLabel }}</span>
            </div>
        </button>
        <ul v-show="isOpen" class="dropdown__list">
            <template v-for="option in options">
                <li
                    v-if="typeof option === 'object'"
                    :key="option.value"
                    :class="{ active: activeOption === option.value }"
                >
                    <button
                        :class="{ active: activeOption === option.value }"
                        class="button"
                        @click="changeOption(option)"
                    >
                        <component
                            :is="option.icon"
                            v-if="option.icon"
                            width="15"
                        />

                        {{ option.label }}
                    </button>
                </li>
                <li
                    v-else
                    :key="option"
                    :class="{ active: activeOption === option }"
                >
                    <button class="button" @click="changeOption(option)">
                        {{ option }}
                    </button>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import SortedIcon from 'Sprites/dropdown_arrow.svg?inline';

export default {
    components: { SortedIcon },
    props: {
        defaultOption: {
            type: [String, Number, Object],
            default: '',
        },
        options: {
            type: Array,
            required: true,
        },
        size: { type: String, default: 'lg' },
        sortBy: { type: Boolean, default: false },
        searchSection: { type: Boolean, default: false },
    },
    data() {
        return {
            isOpen: false,
            activeOption: '',
        };
    },
    computed: {
        dropdownSize() {
            return this.size ? `dropdown--${this.size}` : '';
        },
        dropdownLabel() {
            const option = this.options.find(
                (item) =>
                    item.value === this.activeOption ||
                    item === this.activeOption
            );
            return option ? option.label || option : '';
        },
    },
    watch: {
        defaultOption(val) {
            this.setOption(val);
        },
    },
    created() {
        this.setOption(this.defaultOption);
    },
    mounted() {
        window.addEventListener('click', this.hideDropdown);
        window.addEventListener('touchstart', this.hideDropdown); // iOS specific event
    },
    beforeDestroy() {
        window.removeEventListener('click', this.hideDropdown);
        window.removeEventListener('touchstart', this.hideDropdown); // iOS specific event
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        hideDropdown() {
            if (this.isOpen) {
                this.isOpen = false;
            }
        },
        changeOption(option) {
            this.hideDropdown();
            this.$refs.label.focus();
            this.setOption(option);
            this.$emit('change', option.value);
        },
        setOption(val) {
            if (this.activeOption !== val) {
                this.activeOption = val;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.dropdown {
    display: inline-block;
    position: relative;

    .dropdown__label {
        position: relative;
        z-index: 1;
        width: 100%;
        background: none;
        outline: 0;
        transition: all $transition-values;
        padding: 0 rem(18);
        border: 1px solid $blue-main;
        border-radius: 4px;
        display: flex;
        align-items: center;

        text-transform: capitalize;
        text-align: left;

        &:focus,
        &:hover {
            border: 1px solid #a4ddef;
        }

        // need autoprefixer to work properly
        &::placeholder {
            color: $fake-white;
        }

        svg.rotated {
            transform: rotate(180deg);
        }

        .button__text {
            color: $blue-main;
            display: flex;
            flex-direction: column;
            margin-left: rem(20);
            text-transform: capitalize;
            &--top {
                font-size: 11px;
                letter-spacing: 0.38px;
            }
            &--bottom {
                font-size: 1rem;
                letter-spacing: 0.48px;
                font-weight: 700;
                line-height: 16px;
            }
        }
    }

    .dropdown__list {
        position: absolute;
        z-index: 99;
        left: 0;
        min-width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.18);
        border-radius: 4px;
        background-color: #fbfcfe;

        li {
            position: relative;
            button {
                display: block;
                width: 100%;
                padding: 0.5rem 1rem;

                color: $blue-main;
                text-align: left;
                text-transform: capitalize;
                font-size: 1rem;
                font-weight: 400;
                letter-spacing: 0.48px;
                line-height: 16px;

                &:hover,
                &:focus,
                &.active {
                    color: #a4ddef;
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 0.48px;
                    line-height: 16px;
                    &:before {
                        transition: opacity 0.3s ease-in-out;
                        opacity: 1;
                    }
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    display: inline-block;
                    height: 17px;
                    width: 5px;
                    background-color: #a4ddef;
                    transition: opacity 0.3s ease-in-out;
                    opacity: 0;
                }
            }
        }

        @media (max-width: $md-down) {
            font-size: 1.25em;

            li {
                button {
                    padding-top: 0.25em;
                    padding-bottom: 0.25em;
                }
            }
        }
    }
}

.dropdown--lg {
    font-size: rem(18);

    .dropdown__label {
        height: rem(47);

        @media (max-width: $sm-down) {
            height: rem(36);
        }
    }
}

.dropdown--search {
    .dropdown__label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>

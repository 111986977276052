//Misc
export const LOADING = 'LOADING';
export const LOADING_ASSETS = 'LOADING_ASSETS';
export const RECEIVE_ASSETS = 'RECEIVE_ASSETS';

//User
export const RECEIVE_USER = 'RECEIVE_USER';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';

//Tags
export const RECEIVE_TAGS = 'RECEIVE_TAGS';

//Authors
export const RECEIVE_AUTHORS = 'RECEIVE_AUTHORS';

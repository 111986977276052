'use strict';
import Vue from 'vue';
import * as types from './../mutation-types';

import { UserResource } from '@/services/user';

const state = {
    data: {},
    isLogged: false,
    loading: false,
};

const getters = {
    getUser: (state) => {
        return state.data;
    },
    accessToken: (state) => {
        return state.data && state.data.accessToken;
    },
    getUserId: (state) => {
        return state.data ? state.data.id : null;
    },
    isLogged: (state) => {
        return state.isLogged;
    },
    loading: (state) => {
        return state.loading;
    },
};

const actions = {
    loadUser({ commit }) {
        commit(types.LOADING, true);
        return UserResource.get()
            .then((response) => {
                commit(types.RECEIVE_USER, response.data);
                commit(types.IS_LOGGED_IN, true);
                commit(types.LOADING, false);
            })
            .catch(() => {
                commit(types.RECEIVE_USER, null);
                commit(types.IS_LOGGED_IN, false);
                commit(types.LOADING, false);
            });
    },
};

const mutations = {
    [types.RECEIVE_USER](state, val) {
        Vue.set(state, 'data', val);
    },
    [types.IS_LOGGED_IN](state, val) {
        Vue.set(state, 'isLogged', val);
    },
    [types.LOADING](state, boolean) {
        Vue.set(state, 'loading', boolean);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};

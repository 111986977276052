const state = {
    data: {},
    webp: false,
    youtubeApiLoaded: false,
    previewSizes: [240, 480, 640, 1024, 1280, 1920],
    mobile: false,
    iOS: false,
    operaTouch: false,
};

const getters = {
    youtubeApiLoaded: (state) => {
        return state.youtubeApiLoaded;
    },
    settings: (state) => {
        return state.data;
    },
    thumbsWebpSupport: (state) => {
        return state.data.thumbsWebpSupport;
    },
    webp: (state) => {
        return state.webp;
    },
    previewSizes: (state) => {
        return state.previewSizes;
    },
    thumbnailsUrl: (state) => {
        return state.data.thumbnailsUrl;
    },
    isMobile: (state) => {
        return state.mobile;
    },
    isIOS: (state) => {
        return state.iOS;
    },
    isOperaTouch: (state) => {
        return state.operaTouch;
    },
};

const actions = {};

const mutations = {
    webp(state, val) {
        state.webp = val;
    },
    youtubeApiLoaded(state, val) {
        state.youtubeApiLoaded = val;
    },
    mobileAndTabletChecked(state, val) {
        state.mobile = val;
    },
    iOSChecked(state, val) {
        state.iOS = val;
    },
    operaTouchChecked(state, val) {
        state.operaTouch = val;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import Vue from './../init';

const uri = 'user';

var customActions = {
    assets: { method: 'GET', url: uri + '/assets' },
    subscriptions: { method: 'GET', url: uri + '/subscriptions' },
    profile: { method: 'GET', url: uri + '' },
};

export const UserResource = Vue.resource(uri, {}, customActions);

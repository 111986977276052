<template>
  <div>
    <SearchSection
      ref="searchSection"
      :name="pagination.text"
      :author="pagination.ylands_username"
      :tags="pagination.tags"
      :loading="loading"
      :sort-by="pagination.order_fields"
      @update:pagination="paginationOffset"
      @update:name="changeByName"
      @update:author="onAuthorsChange"
      @update:tags="onTagsChange"
      @update:sortBy="changeSortBy"
    />

    <main v-if="assets">
      <transition name="fade">
        <router-view
          v-if="assets.rows.length > 0"
          v-bind="routerProps"
          @update:rowsPerPage="changeRowsPerPage"
        ></router-view>

        <template v-else>
          <div class="col-12">
            <figure class="asset-net__no-data">
              <Webp
                webp="game-characters.webp"
                fallback="game-characters.png"
                :alt="'Game Characters'"
              />
              <figcaption>No data</figcaption>
            </figure>
          </div>
        </template>
      </transition>
    </main>
  </div>
</template>

<script>
import Vue from 'vue';
import EventBus from '@/EventBus';

import { AssetsResource } from '@/services/assets';
import { mapActions, mapGetters } from 'vuex';
import {
  HOME_PAGE_NAME_GRID,
  HOME_PAGE_NAME_SLIDER,
} from '@/router/pageNameConstants';

import SearchSection from './../components/SearchSection.vue';
import Webp from '@/components/common/Webp';

export default {
  name: 'IndexPage',
  components: {
    SearchSection,
    Webp,
  },
  data() {
    return {
      HOME_PAGE_NAME_GRID,
      loading: false,
      ylands_username: null,
      assets: null,
      searchType: null,
      shownAssets: [],
      val: 0,
      counter: 0,
    };
  },
  computed: {
    ...mapGetters('assets', {
      featuredAssets: 'featuredAssets',
      popularAssets: 'popularAssets',
      newAssets: 'newAssets',
    }),
    ...mapGetters('settings', {
      mobile: 'isMobile',
      iOS: 'isIOS',
    }),
    pagination() {
      this.val;

      let query = this.$route.query;
      let params = {
        content_type: 3,
        limit: 30,
        offset: 0,
        order_direction: ['asc'],
        order_fields: ['subscribers_count'],
        include_tags: true,
        tags: [],
        platform_compatibility: this.mobile || this.iOS ? 1 : 0,
      };

      if (Object.keys(query).length > 0) {
        if (query.limit) {
          params.limit = parseInt(query.limit);
        }
        if (query.offset) {
          params.offset = parseInt(query.offset);
        }
        if (query.order_fields) {
          if (query.order_fields instanceof Array) {
            params.order_fields = query.order_fields;
          } else {
            params.order_fields = Array(query.order_fields);
          }
        }
        if (query.order_direction) {
          if (query.order_direction instanceof Array) {
            params.order_direction = query.order_direction;
          } else {
            params.order_direction = Array(query.order_direction);
          }
        }
        if (query.main) {
          params.main = isNaN(query.main) ? query.main : parseInt(query.main);
        }
        if (query.text) {
          params.text = query.text;
        }
        if (query.tags) {
          params.tags =
            typeof query.tags === 'string' ? query.tags.split('.') : query.tags;
        }
        if (query.ylands_username) {
          params.ylands_username = query.ylands_username;
        }
        if (query.featured) {
          params.featured = query.featured;
        }
        if (query.include_tags) {
          params.include_tags = query.include_tags;
        }
      }

      return params;
    },
    routerProps() {
      if (this.assets) {
        if (this.$route.name === HOME_PAGE_NAME_SLIDER) {
          return {
            newAssets: this.newAssets,
            featuredAssets: this.featuredAssets,
            popularAssets: this.popularAssets,
          };
        }
        if (this.$route.name === HOME_PAGE_NAME_GRID && this.assets.rows) {
          return {
            assets: this.shownAssets,
            pagination: this.pagination,
            totalAssetsCount: this.assets.count,
            loading: this.loading,
          };
        }
      }
      return [];
    },
  },
  watch: {
    pagination() {
      this.loadAssets();
    },
  },
  mounted() {
    this.loadAssets();
    this.loadFeaturedAssets();
    this.loadPopularAssets();
    this.loadNewAssets();

    EventBus.$on(['show-all', 'tag-clicked'], () => {
      this.shownAssets = [];
    });
  },
  beforeDestroy() {
    EventBus.$off(['show-all', 'tag-clicked']);
  },
  methods: {
    ...mapActions('assets', {
      loadFeaturedAssets: 'loadFeaturedAssets',
      loadPopularAssets: 'loadPopularAssets',
      loadNewAssets: 'loadNewAssets',
    }),
    paginationOffset() {
      this.changeByName('');

      if (this.counter !== 0) {
        this.val++;
      }

      if (this.pagination.limit !== 30) {
        this.refreshPagination();
        this.updateUrl();
      }
      this.counter++;
    },
    loadAssets() {
      this.loading = true;

      AssetsResource.get(this.pagination)
        .then((response) => {
          this.loading = false;
          this.assets = response.data;
          this.assets.rows.forEach((element) => {
            this.shownAssets.push(element);
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async updateUrl() {
      await this.$router
        .replace({
          name: HOME_PAGE_NAME_GRID,
          query: this.pagination,
        })
        .catch(() => {});
    },
    onAuthorsChange(authors) {
      this.refreshPagination();
      let authorUsername = '';
      if (authors.length > 0) {
        authorUsername = authors.map((item) => {
          return item.ylands_username;
        });
      }

      if (this.pagination.ylands_username === authorUsername[0]) {
        return;
      }

      this.pagination.ylands_username = authors.length
        ? authors[0].ylands_username
        : undefined;

      this.shownAssets = [];
      this.updateUrl();
    },
    onTagsChange(tags) {
      this.refreshPagination();
      let tagIds = tags.map((item) => {
        this.shownAssets = [];
        return item.id ? item.id : item;
      });

      Vue.set(this.pagination, 'tags', tagIds);
      Vue.set(this.pagination, 'length', tagIds.length); //TODO this is to trigger update
      this.shownAssets = [];
      this.updateUrl();
    },
    changeRowsPerPage(offset, limit) {
      this.pagination.offset = offset;
      this.pagination.limit = limit;
      this.updateUrl();
    },
    refreshPagination() {
      this.pagination.limit = 30;
      this.pagination.offset = 0;

      this.$router.push({
        name: HOME_PAGE_NAME_GRID,
        query: this.pagination,
      });
    },
    changeByName(name) {
      this.refreshPagination();
      this.pagination.text = name;
      this.shownAssets = [];
      this.updateUrl();
    },
    changeSortBy(sortBy) {
      if (sortBy == 'released_at' || sortBy == 'rating') {
        this.pagination.order_direction = ['desc'];
      } else {
        this.pagination.order_direction = ['asc'];
      }
      if (sortBy.toString() != this.pagination.order_fields.toString()) {
        this.shownAssets = [];
      }
      if (sortBy instanceof Array) {
        this.pagination.order_fields = sortBy;
      } else {
        this.pagination.order_fields = Array(sortBy);
      }
      this.pagination.offset = 0;
      this.pagination.limit = 30;

      this.updateUrl();
    },
  },
};
</script>

<style scoped lang="scss">
.asset-net__no-data {
  margin: 0;
  padding: 50px 0;
  text-align: center;
  opacity: 0.3;

  img {
    width: 225px;
  }

  figcaption {
    font-size: 2.5rem;
    color: $blue-main;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-wrapper"},_vm._l((_vm.itemsList),function(item,index){return _c('div',{key:index,staticClass:"tag",class:{
            'tag--removable': _vm.removable,
            'tag--orange': item.type === 'author',
            'tag--green': item.type === 'type',
            'tag--search': _vm.searchSection,
            active: _vm.activeOption.value == item.value,
        },attrs:{"title":item.name},on:{"click":function($event){return _vm.toggleItem(item)}}},[_c(item.icon,{tag:"component",attrs:{"width":"15"}}),_c('span',[_vm._v(" "+_vm._s(item.name ? item.name.replace('YlandsGenre_', '') : item.label.replace('YlandsGenre_', ''))+" ")]),(_vm.removable)?_c('button',{staticClass:"button icon-x",attrs:{"title":"remove"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.remove(item, index)}}},[_c('CloseIcon',{attrs:{"width":"11","height":"11"}})],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
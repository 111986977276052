<template>
  <div v-if="settings && !loading" id="app">
    <template v-if="onlyView">
      <router-view></router-view>
    </template>

    <template v-else>
      <main-header
        login-url="/api/v1.0/auth/"
        logout-url="/api/v1.0/auth/logout"
        user-data-url="/profile/creations"
        :user-data="user"
        :hoverable="false"
        :use-native-links="true"
        always-active-link="play"
      ></main-header>
      <div class="divider divider--header bg--main"></div>

      <notifications></notifications>

      <div id="top-section">
        <section id="top-section-banner" class="top-section">
          <TopSection />
        </section>
      </div>

      <div class="custom-container">
        <transition name="fade">
          <router-view></router-view>
        </transition>
        <Feedback />
      </div>

      <div class="divider divider--foter"></div>
      <MainFooter />
    </template>

    <SessionDialog />
  </div>
  <div
    v-else
    style="
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #0a294a;
    "
  >
    <img
      src="../src/assets/img/loaders/oval.svg"
      alt="Loading"
      width="100px"
      height="100px"
    />
  </div>
</template>

<script>
import MainFooter from '@bistudio/ylands-vue-components/src/components/Footer';
import SessionDialog from '@/components/common/modal/SessionDialog';

import TopSection from '@/components/TopSection';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    MainFooter,
    SessionDialog,
    TopSection,
    Feedback: () =>
      import(/* webpackPrefetch: true */ '@/components/common/Feedback.vue'),
  },
  computed: {
    ...mapGetters('settings', {
      settings: 'settings',
    }),
    ...mapGetters('user', {
      user: 'getUser',
      loading: 'loading',
    }),
    onlyView() {
      return this.$route.meta.onlyView;
    },
  },

  methods: {
    ...mapActions('user', ['loadUser']),
  },
};
</script>

<style lang="scss">
#app {
  background-color: #f6f8fb;
}

#top-section {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 93%);
  background-image: url('~Images/Ylands-PolyBackground-skyblue-02.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $transition-values;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

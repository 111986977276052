<template>
    <div class="sorting" aria-label="Sort assets">
        <Dropdown
            :size="'lg'"
            :options="types"
            :sort-by="true"
            class="dropdown"
            :class="{ loading: loading }"
            :default-option="sortBy.toString()"
            @change="changeSortBy"
        />

        <div v-if="loading" class="sorting__loader loader-rounded"></div>
    </div>
</template>

<script>
import Dropdown from '@/components/common/Dropdown.vue';

export default {
    components: { Dropdown },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            types: [
                { value: 'name', label: 'Title', id: 1 },
                { value: 'released_at', label: 'Release date', id: 2 },
                { value: 'rating', label: 'Rating', id: 3 },
                { value: 'subscribers_count', label: 'Popularity', id: 4 },
            ],
        };
    },
    computed: {
        sort() {
            return {
                by: this.sortBy,
            };
        },
    },
    methods: {
        changeSortBy(sortBy) {
            this.sort.by = sortBy;
            this.$emit('update:sortBy', sortBy);
        },
    },
};
</script>

<style lang="scss" scoped>
.sorting {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .dropdown {
        position: relative;
        max-width: 206px;
        width: 100%;

        &.loading {
            opacity: 0.1;
        }
    }
    .sorting__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>

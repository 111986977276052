const HOME_PAGE_NAME_SLIDER = 'home';
const HOME_PAGE_NAME_GRID = 'home-grid';

const PROFILE_PAGE_NAME = 'profile';
const SUBSCRIPTIONS_PAGE_NAME = 'profile-subscriptions';
const CREATIONS_PAGE_NAME = 'profile-creations';

const AUTHOR_PROFILE_PAGE_NAME = 'author-profile';
const ASSET_DETAIL_PAGE_NAME = 'asset-detail';

const NOT_FOUND_PAGE_NAME = 'not-found';
const ERROR_PAGE_NAME = 'error';

export {
    PROFILE_PAGE_NAME,
    CREATIONS_PAGE_NAME,
    SUBSCRIPTIONS_PAGE_NAME,
    AUTHOR_PROFILE_PAGE_NAME,
    ASSET_DETAIL_PAGE_NAME,
    HOME_PAGE_NAME_SLIDER,
    HOME_PAGE_NAME_GRID,
    NOT_FOUND_PAGE_NAME,
    ERROR_PAGE_NAME,
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Home from './../views/Home.vue';
const Profile = () => import('./../views/Profile.vue');
const NotFound = () => import('./../views/NotFound.vue');
const Error = () => import('./../views/Error.vue');
const Asset = () => import('./../views/Asset.vue');

import {
    ASSET_DETAIL_PAGE_NAME,
    AUTHOR_PROFILE_PAGE_NAME,
    CREATIONS_PAGE_NAME,
    ERROR_PAGE_NAME,
    HOME_PAGE_NAME_SLIDER,
    HOME_PAGE_NAME_GRID,
    NOT_FOUND_PAGE_NAME,
    PROFILE_PAGE_NAME,
    SUBSCRIPTIONS_PAGE_NAME,
} from './pageNameConstants';

Vue.use(VueRouter);

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        // savedPosition is only available for popstate navigations.
        return savedPosition;
    } else if (from.path == to.path) {
        return;
    } else {
        const position = {
            x: 0,
            y: 0,
        };

        // new navigation.
        // scroll to anchor by returning the selector
        if (to.hash) {
            position.selector = to.hash;
        }
        // check if any matched route config has meta that requires scrolling to top
        if (to.matched.some((m) => m.meta.scrollToTop)) {
            // cords will be used if no selector is provided,
            // or if the selector didn't match any element.
            position.x = 0;
            position.y = 0;
        }
        // if the returned position is falsy or an empty object,
        // will retain current scroll position.

        if (to.path === '/') {
            // position.y = document.getElementById('assets-main-filter-wrapper').offsetTop - 150;
        }

        return position;
    }
};

const routes = [
    {
        path: '/',
        component: Home,
        children: [
            {
                name: HOME_PAGE_NAME_SLIDER,
                path: '',
                props: true,
                component: () => import('./../components/LandingSliders.vue'),
                beforeEnter(to, from, next) {
                    if (Object.keys(to.query).length > 0) {
                        next({
                            name: HOME_PAGE_NAME_GRID,
                            query: to.query,
                        });
                    } else next();
                },
            },
            {
                name: HOME_PAGE_NAME_GRID,
                path: '',
                component: () =>
                    import('./../components/assetsTable/AssetsGrid.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/asset/:id',
        name: ASSET_DETAIL_PAGE_NAME,
        component: Asset,
    },
    {
        path: '/profile',
        component: Profile,
        name: PROFILE_PAGE_NAME,
        exact: true,
        redirect: '/profile/creations',
    },
    {
        path: '/profile/creations',
        name: CREATIONS_PAGE_NAME,
        component: Profile,
    },
    {
        path: '/profile/subscriptions',
        name: SUBSCRIPTIONS_PAGE_NAME,
        component: Profile,
    },
    {
        path: '/profiles/:id',
        name: AUTHOR_PROFILE_PAGE_NAME,
        component: Profile,
    },
    {
        path: '/error',
        name: ERROR_PAGE_NAME,
        component: Error,
        meta: { onlyView: true },
    },
    {
        path: '*',
        name: NOT_FOUND_PAGE_NAME,
        component: NotFound,
        meta: { onlyView: true },
    },
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior,
    routes,
});

let userIsLoaded = false;
const loadUser = store.dispatch('user/loadUser');

router.beforeEach((to, from, next) => {
    if (!userIsLoaded) {
        loadUser.then(() => {
            userIsLoaded = true;
            next();
        });

        return;
    }

    next();
});

export default router;

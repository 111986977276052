var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.path === _vm.AUTHOR_PROFILE_PAGE_NAME)?_c('UserProfile'):(
        [
            _vm.CREATIONS_PAGE_NAME,
            _vm.PROFILE_PAGE_NAME,
            _vm.SUBSCRIPTIONS_PAGE_NAME,
        ].indexOf(_vm.path) >= 0
    )?_c('MyProfile'):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
'use strict';

import { AuthorsResource } from '@/services/authors';
import * as types from './../mutation-types';

const state = {
    authors: {
        rows: [],
    },
    loading: false,
};

const getters = {
    getAuthors(state) {
        return state.authors.rows;
    },
    loading: state => {
        return state.loading;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    load({ commit, state }, params) {
        commit(types.LOADING, true);
        return AuthorsResource.get(params)
            .then(response => {
                commit(types.RECEIVE_AUTHORS, response.data);
                commit(types.LOADING, false);
            })
            .catch(() => {
                commit(types.RECEIVE_AUTHORS, {
                    rows: [],
                });
                commit(types.LOADING, false);
            });
    },
};

const mutations = {
    [types.RECEIVE_AUTHORS](state, authors) {
        state.authors = authors;
    },
    [types.LOADING](state, val) {
        state.loading = val;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

<template>
    <transition name="modal">
        <div class="modal">
            <div
                ref="mask"
                class="modal-mask"
                :class="[containerSize]"
                @click="onClickOutside"
            >
                <div class="modal-dialog" :class="`modal-dialog-${size}`">
                    <slot name="body"> default body </slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
const ClassName = {
    SCROLLBAR_MEASURER: 'modal-scrollbar-measure',
    OPEN: 'modal-open',
};

export default {
    props: {
        closeOnOutside: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            showModal: false,
            // hide scrollbar related stuff
            isBodyOverflowing: null,
            scrollbarWidth: null,
            originalBodyPadding: 0,
            multipleDialogs: false,
        };
    },
    computed: {
        containerSize() {
            return this.size ? `modal-mask--${this.size}` : '';
        },
    },
    mounted() {
        if (!document.body.classList.contains(ClassName.OPEN)) {
            this.checkScrollbar();
            this.setScrollbar();
            document.body.classList.add(ClassName.OPEN);
        } else {
            this.multipleDialogs = true;
        }
    },
    destroyed() {
        if (!this.multipleDialogs) {
            document.body.classList.remove(ClassName.OPEN);
            this.resetScrollbar();
        }
    },
    methods: {
        onClickOutside(e) {
            if (this.closeOnOutside && e.target === this.$refs.mask) {
                this.close();
            }
        },
        close() {
            this.$emit('close');
        },
        // hide scrollbar related methods
        checkScrollbar() {
            this.isBodyOverflowing =
                document.body.clientWidth < window.innerWidth;
            this.scrollbarWidth = this.getScrollbarWidth();
        },
        setScrollbar() {
            this.originalBodyPadding = document.body.style.paddingRight || '';

            if (this.isBodyOverflowing) {
                document.body.style.paddingRight = `${this.scrollbarWidth}px`;
            }
        },
        resetScrollbar() {
            document.body.style.paddingRight = this.originalBodyPadding;
        },
        getScrollbarWidth() {
            const scrollDiv = document.createElement('div');
            scrollDiv.className = ClassName.SCROLLBAR_MEASURER;
            document.body.appendChild(scrollDiv);
            const scrollbarWidth =
                scrollDiv.offsetWidth - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);
            return scrollbarWidth;
        },
    },
};
</script>

<template>
    <div
        class="notification notification-animation"
        :class="`notification--${notification.type}`"
        @mouseover="cancelTimeout"
        @mouseout="runTimeout"
    >
        <div
            class="notification__progress"
            :style="{ transform: `scaleX(${progress})` }"
        ></div>

        <span class="notification__text">{{ notification.message }}</span>

        <button
            v-if="notification.cb"
            class="button notification__undo"
            @click="undo"
        >
            Undo
        </button>

        <button class="button notification__close" @click="close">
            <CloseIcon />
        </button>
    </div>
</template>

<script>
import CloseIcon from 'Sprites/close.svg?inline';

export default {
    components: { CloseIcon },
    props: {
        notification: { type: Object, default: () => {} },

        timeout: {
            type: Number,
            default: 3000,
        },
    },
    data() {
        return {
            initialTime: null,
            progress: 0,
            req: null,
            extra: 0,
        };
    },
    mounted() {
        this.runTimeout();
    },
    methods: {
        runTimeout() {
            this.initialTime = new Date();

            if (this.progress > 0) {
                this.extra = this.progress * this.timeout;
            } else {
                this.extra = 0;
            }

            this.req = window.requestAnimationFrame(this.initLoader);
        },
        cancelTimeout() {
            window.cancelAnimationFrame(this.req);
        },
        initLoader() {
            const currentTime = new Date();
            const diff = currentTime - this.initialTime + this.extra;

            if (diff >= this.timeout) {
                this.progress = 1;
                this.close();
                return;
            }

            this.progress = diff / this.timeout;
            this.req = window.requestAnimationFrame(this.initLoader);
        },
        removeNotification() {
            this.$emit('remove');
        },
        undo() {
            this.cancelTimeout();
            this.initCallback(true);
            this.removeNotification();
        },
        close() {
            this.cancelTimeout();
            this.initCallback(false);
            this.removeNotification();
        },
        initCallback(result) {
            if (this.notification.cb) {
                this.notification.cb(result);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.notifications-wrap {
    position: fixed;
    z-index: 999;
    bottom: 20px;
    right: 25px;
}

.notification {
    position: relative;
    overflow: hidden;
    font-size: 1rem;
    padding: 1em 2em 1em 1em;
    margin-bottom: 1em;
    max-width: rem(220);
    min-width: rem(180);
    border-radius: 0.25em;
}

.notification--success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;

    .notification__progress {
        background-color: darken(#3c763d, 10%);
    }

    svg {
        fill: darken(#3c763d, 10%);
    }
}

.notification--error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;

    .notification__progress {
        background-color: darken(#8a6d3b, 10%);
    }

    svg {
        fill: darken(#a94442, 10%);
    }
}

.notification--info {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;

    .notification__progress {
        background-color: darken(#8a6d3b, 10%);
    }

    svg {
        fill: darken(#8a6d3b, 10%);
    }
}

.notification__undo {
    font-family: $istok-font;
    font-weight: 600;
    text-decoration: underline;
}

.notification__close {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;

    svg {
        width: 0.8em;
        height: 0.8em;
    }
}

.notification__text {
    strong {
        font-weight: 600;
    }

    a {
        color: currentColor;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.notification__progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #8a6d3b;
    transform-origin: 0 50%;
    transform: scaleX(0);
}

.notification-animation {
    animation: animation 1500ms linear both;
}

@keyframes animation {
    0% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 250, 0, 0, 1);
    }
    3.14% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            160.737,
            0,
            0,
            1
        );
    }
    4.3% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            132.565,
            0,
            0,
            1
        );
    }
    6.27% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            91.357,
            0,
            0,
            1
        );
    }
    8.61% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            51.939,
            0,
            0,
            1
        );
    }
    9.41% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            40.599,
            0,
            0,
            1
        );
    }
    12.48% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 6.498, 0, 0, 1);
    }
    12.91% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.807, 0, 0, 1);
    }
    16.22% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -17.027,
            0,
            0,
            1
        );
    }
    17.22% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -20.404,
            0,
            0,
            1
        );
    }
    19.95% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -24.473,
            0,
            0,
            1
        );
    }
    23.69% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -21.178,
            0,
            0,
            1
        );
    }
    27.36% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -13.259,
            0,
            0,
            1
        );
    }
    28.33% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -11.027,
            0,
            0,
            1
        );
    }
    34.77% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.142, 0, 0, 1);
    }
    39.44% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.725, 0, 0, 1);
    }
    42.18% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.675, 0, 0, 1);
    }
    56.99% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -0.202,
            0,
            0,
            1
        );
    }
    61.66% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -0.223,
            0,
            0,
            1
        );
    }
    66.67% {
        transform: matrix3d(
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            -0.104,
            0,
            0,
            1
        );
    }
    83.98% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.01, 0, 0, 1);
    }
    100% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
}
</style>

<template>
    <div class="notifications-wrap">
        <NotificationsItem
            v-for="(notification, id) in notifications"
            :key="id"
            :notification="notification"
            :timeout="notification.timeout"
            @remove="removeNotification(id)"
        />
    </div>
</template>

<script>
import EventBus from '../../EventBus';
import NotificationsItem from './NotificationsItem.vue';

export default {
    components: {
        NotificationsItem,
    },
    data() {
        return {
            notifications: {},
            time: 5000,
            defaultId: 9999,
        };
    },
    created() {
        EventBus.$on('AddNewNotification', (message, type, options) => {
            this.addNotification(message, type, options);
        });
    },
    methods: {
        removeNotification(id) {
            this.$delete(this.notifications, id);
        },
        addNotification(message, type, options) {
            const newId = this.generateId();
            const newObj = Object.assign({ message, type }, options);
            this.$set(this.notifications, newId, newObj);
        },
        generateId() {
            return this.defaultId--;
        },
    },
};
</script>

<style lang="scss" scoped>
.notifications-wrap {
    position: fixed;
    z-index: 999;
    bottom: 20px;
    right: 25px;
}
</style>

import Vue from './../init';

const uri = 'author';

var customActions = {
    profile: { method: 'GET', url: uri + '/{id}' },
    watch: { method: 'PUT', url: uri + '/{id}/watch' },
};

export const AuthorsResource = Vue.resource(uri, {}, customActions);

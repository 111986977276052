<template>
    <a
        class="button-back"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
    >
        <ArrowLeftIcon class="button-back__icon" width="15" fill="#0a294a" />
        <span>Return</span>
    </a>
</template>

<script>
//SVG
import ArrowLeftIcon from 'Sprites/return_arrow.svg?inline';

export default {
    components: { ArrowLeftIcon },
    props: {
        historyReplace: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        hasHistory() {
            if (this.historyReplace) {
                return false;
            } else {
                return window.history.length > 2;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.button-back {
    position: relative;
    z-index: 10;
    color: $blue-main;
    font-size: rem(18);
    letter-spacing: 0.07px;
    transition: all $transition-values;
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    display: inline-block;

    .button-back__icon {
        transition: $transition-values;
    }

    &:hover {
        .button-back__icon {
            animation: move 0.8s linear infinite;
        }
        @keyframes move {
            0% {
                transform: translateX(0px);
            }
            50% {
                transform: translateX(-5px);
            }
            100% {
                transform: translateX(0px);
            }
        }
    }

    span {
        display: inline-block;
        margin-left: 5px;
        border-bottom: 1px solid;
        line-height: 1.2;
    }

    &:hover,
    &:focus {
        color: #82919f;

        svg {
            fill: #82919f;
        }
    }
}
</style>

o
<template>
    <div class="account">
        <div class="account__avatar">
            <ProfileAvatar :image="profileImg()" />
        </div>
        <div class="account__name_and_icon">
            <h2
                :title="`Usernmae: ${profile && profile.ylands_username}`"
                class="account__header heading heading--lg heading--thin"
            >
                {{
                    profile && profile.ylands_username
                        ? profile.ylands_username + ' (you)'
                        : 'Your profile'
                }}
            </h2>
            <span
                v-if="profile && profile.verified"
                title="Verified author"
                class="account__icon"
            >
                <VerifiedBadgeIcon
                    class="account__verified-icon"
                    title="Verified Author"
                />
            </span>
        </div>
        <div class="account__info">
            <ul class="account__list">
                <li>
                    <strong>My creations: </strong>
                    <router-link :to="{ name: CREATIONS_PAGE_NAME }">
                        <template v-if="profile.my_assets">
                            {{ myAssetsCount }}
                        </template>
                    </router-link>
                </li>
                <li>
                    <strong>Watching: </strong>
                    <template v-if="profile.profile">
                        {{ watchedAuthorsCount }}
                    </template>
                </li>
                <li>
                    <strong>Favorites: </strong>
                    <router-link :to="{ name: SUBSCRIPTIONS_PAGE_NAME }">
                        <template v-if="profile.profile">
                            {{ subscriptionsCount }}
                        </template>
                    </router-link>
                </li>
                <li
                    v-if="
                        profile &&
                        profile.coins &&
                        profile.coins.hard &&
                        profile.coins.hard > 0
                    "
                >
                    <strong>Coins: </strong>
                    {{ formatNumber(profile.coins.hard) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {
    CREATIONS_PAGE_NAME,
    SUBSCRIPTIONS_PAGE_NAME,
} from '@/router/pageNameConstants';

import { UserResource } from '@/services/user';

import ProfileAvatar from '../common/ProfileAvatar.vue';

import VerifiedBadgeIcon from 'Sprites/verified_badge.svg?inline';

export default {
    components: {
        ProfileAvatar,
        VerifiedBadgeIcon,
    },
    data() {
        return {
            profile: {},
            CREATIONS_PAGE_NAME,
            SUBSCRIPTIONS_PAGE_NAME,
        };
    },
    computed: {
        subscriptionsCount() {
            if (this.profile.profile.subscriptions !== undefined) {
                const subscriptionCount = this.profile.profile.subscriptions
                    .length;

                return `${this.formatNumber(subscriptionCount)} creation${
                    subscriptionCount !== 1 ? 's' : ''
                }`;
            } else {
                return 'None :(';
            }
        },
        watchedAuthorsCount() {
            if (this.profile.profile.favoriteAuthors !== undefined) {
                const authorsCount = this.profile.profile.favoriteAuthors
                    .length;

                return `${this.formatNumber(authorsCount)} author${
                    authorsCount !== 1 ? 's' : ''
                }`;
            } else {
                return 'None :(';
            }
        },
        myAssetsCount() {
            if (this.profile.my_assets !== undefined) {
                const assetsCount = this.profile.my_assets;

                if (assetsCount <= 0) {
                    return 'you have no creations';
                } else {
                    return `${this.formatNumber(assetsCount)} creation${
                        assetsCount !== 1 ? 's' : ''
                    }`;
                }
            } else {
                return null;
            }
        },
    },
    created() {
        this.loadProfile();
    },
    methods: {
        async loadProfile() {
            let response = await UserResource.profile({
                getFavoriteAuthors: true,
                getSubscriptions: true,
                getMyAssets: true,
            });
            let assets = await UserResource.assets({});
            this.profile = Object.assign(response.body, {
                my_assets: assets.body.count,
            });
        },
        formatNumber(num) {
            return ('' + num).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        profileImg() {
            if (this.profile.avatars) {
                return this.profile.avatars.default.url;
            } else return null;
        },
    },
};
</script>
<style scoped lang="scss">
@import './profile.scss';
.placeholder-text {
    font-size: 0.8em;
    opacity: 0.2;
}
</style>

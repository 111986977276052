<template>
    <div class="tag-wrapper">
        <div
            v-for="(item, index) in itemsList"
            :key="index"
            class="tag"
            :class="{
                'tag--removable': removable,
                'tag--orange': item.type === 'author',
                'tag--green': item.type === 'type',
                'tag--search': searchSection,
                active: activeOption.value == item.value,
            }"
            :title="item.name"
            @click="toggleItem(item)"
        >
            <component :is="item.icon" width="15" />
            <span>
                {{
                    item.name
                        ? item.name.replace('YlandsGenre_', '')
                        : item.label.replace('YlandsGenre_', '')
                }}
            </span>
            <button
                v-if="removable"
                class="button icon-x"
                title="remove"
                @click.prevent.stop="remove(item, index)"
            >
                <CloseIcon width="11" height="11" />
            </button>
        </div>
    </div>
</template>

<script>
import { HOME_PAGE_NAME_GRID } from './../../router/pageNameConstants';
import EventBus from '@/EventBus';

//SVG
import CloseIcon from 'Sprites/close.svg?inline';

export default {
    name: 'ItemsVisualizer',
    components: { CloseIcon },
    props: {
        defaultItems: {
            type: Array,
            default: function () {
                return [];
            },
        },
        removable: {
            type: Boolean,
            default: true,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        authorsArray: {
            type: [Array, String],
            default: function () {
                return [];
            },
        },
        single: { type: Boolean, default: false },
        searchSection: { type: Boolean, default: false },
        value: {
            type: [Array, String],
            default: function () {
                return [];
            },
        },
    },
    data() {
        return { HOME_PAGE_NAME_GRID, activeOption: '' };
    },
    computed: {
        items() {
            return this.defaultItems;
        },
        tagsArray() {
            return this.defaultItems.map((item, index) => {
                return { type: 'tag', index, ...item };
            });
        },
        itemsList() {
            let arr = [];
            //TODO - random tag pick, w8 for API to be a single tag
            if (this.single && this.tagsArray.length) {
                let randomTagNum = Math.floor(
                    Math.random() * this.tagsArray.length
                );
                arr.push(this.tagsArray[randomTagNum]);
                return arr;
            } else {
                if (this.tagsArray.length) {
                    arr.push(...this.tagsArray);
                }

                if (this.authorsArray.length > 0) {
                    arr.push({ type: 'author', name: this.authorsArray });
                }

                return arr;
            }
        },
    },
    mounted() {
        this.items = this.itemsList;
        this.items.map((item) => {
            if (item.value === 'games') {
                this.activeOption = item;
            }
        });
    },
    methods: {
        remove(item) {
            switch (item.type) {
                case 'tag':
                    this.removeItem(item.index);
                    break;
                case 'author':
                    this.removeAuthor();
                    break;
                default:
                    this.items.splice(item, 1);
                    this.$emit('update:remove', this.items);
                    break;
            }
        },
        removeItem(index) {
            this.items.splice(index, 1);
            this.$emit('remove:tag', this.items);
        },
        removeAuthor() {
            this.$emit('remove:author', []);
        },
        toggleItem(item) {
            this.setOption(item);
            if (this.clickable && !this.searchSection) {
                this.$router.push({
                    name: HOME_PAGE_NAME_GRID,
                    query: { tags: [item.id] },
                });
                EventBus.$emit('tag-clicked', item);
            }
            this.$emit('update:clicked', item);
        },
        setOption(val) {
            if (this.activeOption !== val) {
                this.activeOption = val;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tag {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0.9em 0 0;
    padding: 0;
    height: em(35);
    min-width: em(100);
    max-width: em(225);
    text-transform: capitalize;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    color: $blue-main;
    background-color: #71d3b9;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    letter-spacing: 0.06px;

    @media (max-width: $md-down) {
        font-size: 0.87rem;
    }

    svg {
        margin-right: 6px;
    }

    span {
        font-family: $palanquin-font;
        display: inline-block;
        font-size: em(12);
        line-height: rem(35);
        letter-spacing: 0.3px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        i {
            position: relative;
            bottom: -0.075em;
        }
    }

    .icon-x {
        float: right;
        margin-left: em(5);
        padding-left: em(5);
        padding-right: em(8);
        outline: none;
        transition: background-color $transition-values;

        svg {
            fill: $blue-main;
        }
    }

    ////////////////////////////////////////
    // MODIFICATORS
    ////////////////////////////////////////

    // using in assets detail
    &--sm {
        font-size: 0.7rem;
        padding: 0 0.5em;

        span {
            font-size: 1.2em;
            line-height: 2;
        }
    }

    // using in asset dialog
    &--md {
        height: em(28);

        span {
            line-height: 2.4;
        }

        .icon-x {
            line-height: em(28);
        }
    }

    &--author {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        background-color: $yellow-main;

        span {
            flex: 1;
        }

        .icon-x {
            outline: 0;
            border-left-color: $yellow-hover;

            &:hover,
            &:focus {
                background-color: $yellow-hover;
            }
        }
    }

    &--green {
        background-color: #71d3b9;
    }

    &--orange {
        background-color: #f7c185;
    }

    &--violet {
        background-color: #c2b9da;
    }

    &--removable {
        span {
            padding-left: rem(8);
            max-width: 82%;
        }
    }
    &--search {
        background-color: $blue-lighter;
        border-radius: 18px;
        transition: all $transition-values;
        color: $blue-main;
        svg {
            fill: $blue-main;
        }

        &:hover,
        &.active,
        &:focus {
            background-color: $blue-main;
            color: $blue-lighter;

            svg {
                fill: $blue-lighter;
            }
        }
    }
}
</style>

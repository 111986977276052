<template>
    <UserProfile v-if="path === AUTHOR_PROFILE_PAGE_NAME" />

    <MyProfile
        v-else-if="
            [
                CREATIONS_PAGE_NAME,
                PROFILE_PAGE_NAME,
                SUBSCRIPTIONS_PAGE_NAME,
            ].indexOf(path) >= 0
        "
    />
</template>

<script>
import {
    AUTHOR_PROFILE_PAGE_NAME,
    CREATIONS_PAGE_NAME,
    PROFILE_PAGE_NAME,
    SUBSCRIPTIONS_PAGE_NAME,
} from '@/router/pageNameConstants';

import { MyProfile, UserProfile } from './TopSection/index';

export default {
    components: {
        MyProfile,
        UserProfile,
    },
    data() {
        return {
            PROFILE_PAGE_NAME,
            SUBSCRIPTIONS_PAGE_NAME,
            CREATIONS_PAGE_NAME,
            AUTHOR_PROFILE_PAGE_NAME,
        };
    },
    computed: {
        path() {
            return this.$route.name;
        },
    },
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import settings from './modules/settings';
import user from './modules/user';
import tags from './modules/tags';
import authors from './modules/authors';
import assets from './modules/assets';

const store = new Vuex.Store({
    modules: {
        authors,
        settings,
        user,
        tags,
        assets,
    },
});

export default store;

import * as errors from '@/errors';
import debug from 'debug';
import Vue from 'vue';

import VueResource from 'vue-resource';
import EventBus from './EventBus';
import { SESSION_EXPIRED } from './lib/apiCodes';
import { addErrorNotification } from './lib/notifications';

Vue.use(VueResource);
Vue.http.options.root = '/api/v1.0';

const log = debug('workshop:init');

Vue.http.interceptors.push((request, next) => {
    next(response => {
        const status = response.status;

        if (status !== 200) {
            const message = response.body.message;

            if (status === 401) {
                console.log('User not authorized!', message);
                throw new errors.UnauthorizedError(message);
            }

            if (
                response.body.api_code === SESSION_EXPIRED ||
                response.body.api_code === 'authService.tokenExpired'
            ) {
                EventBus.$emit('session-expired');
                throw new errors.UnauthorizedError(message);
            }

            if (status === 504 || status === 502) {
                addErrorNotification(
                    'Service temporaraly unavailable. Please try again later.'
                );
                throw new errors.ServiceUnavailable();
            }

            if (status === 400) {
                throw new errors.ApiError(message, response.body.api_code);
            }

            log('Uncaught error', message);
            throw new Error(message);
        }
    });
});

export default Vue;

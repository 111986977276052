<template>
    <img
        :src="profileImageSrc"
        class="img-responsive"
        :alt="`${username}'s avatar`"
        title="Player avatar"
        loading="lazy"
        width="120"
        height="120"
    />
</template>

<script>
export default {
    props: {
        username: {
            type: String,
            default: 'Your',
        },
        image: { type: String, default: null },
    },
    computed: {
        isWebpSupported() {
            return this.$store.state.settings.webp;
        },
        profileImageSrc() {
            if (this.image) {
                return this.image;
            } else {
                return require(`Images/avatars/avatar_1` +
                    (this.isWebpSupported ? '.webp' : '.png'));
            }
        },
    },
};
</script>

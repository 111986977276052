<template>
    <div class="search-section__search">
        <div
            class="input input--lg input--search input--rounded"
            :class="{ loading: loading }"
        >
            <i>
                <SearchIcon
                    class="icon-search"
                    fill="#41566d"
                    width="14"
                    height="15"
                />
            </i>
            <input
                v-if="filterValue === 'games'"
                id="search-name"
                v-model="searchName"
                type="text"
                placeholder="Search for Games"
            />
            <AuthorsPicker
                v-else-if="filterValue === 'authors'"
                class="search-section__author"
                input-size="lg"
                @change="onAuthorsChange"
            />
            <TagPicker
                v-else-if="filterValue === 'genres'"
                class="search-section__tags"
                :default-tags="defaultItems"
                :show-tags="false"
                input-size="lg"
                @change="onTagsChange"
            />
            <button class="button">
                <ItemsVisualizer
                    v-if="windowWidth > 768"
                    :default-items="listItems"
                    :removable="false"
                    :clickable="true"
                    :single="false"
                    :search-section="true"
                    @update:clicked="onFilterChange"
                />
                <Dropdown
                    v-else
                    :size="'lg'"
                    :options="listItems"
                    :sort-by="false"
                    :default-option="'games'"
                    :search-section="true"
                    :class="{ loading: loading }"
                    @change="onFilterChange"
                />
            </button>
        </div>
        <div v-if="loading" class="sorting__loader loader-rounded"></div>
    </div>
</template>

<script>
import { debounce } from '@/lib/debouncer';

import AuthorsPicker from '@/components/common/AuthorPicker.vue';
import TagPicker from '@/components/common/TagPicker.vue';

import ItemsVisualizer from '@/components/common/ItemsVisualizer.vue';
import Dropdown from '@/components/common/Dropdown.vue';

//SVG
import SearchIcon from 'Sprites/search.svg?inline';
import GamesIcon from 'Sprites/game_search.svg?inline';
import AuthorIcon from 'Sprites/author_active.svg?inline';
import TagIcon from 'Sprites/tag.svg?inline';

const DEFAULT_TYPE = 'games';

export default {
    components: {
        ItemsVisualizer,
        Dropdown,
        SearchIcon,
        AuthorsPicker,
        TagPicker,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        activeType: {
            type: [Number, String, Array, Object],
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Array,
            default: () => [],
        },
        defaultItems: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            listItems: [
                {
                    id: 2,
                    icon: GamesIcon,
                    label: 'Games',
                    value: 'games',
                },
                {
                    id: 3,
                    icon: AuthorIcon,
                    label: 'Authors',
                    value: 'authors',
                },
                {
                    id: 4,
                    icon: TagIcon,
                    label: 'Genres',
                    value: 'genres',
                },
            ],
            windowWidth: window.innerWidth,
            filterValue: DEFAULT_TYPE,
            debouncedInput: '',
        };
    },
    computed: {
        searchName: {
            get() {
                return this.debouncedInput;
            },
            set: debounce(function (val) {
                this.debouncedInput = val;
                this.update(this.debouncedInput);
            }, 500),
        },
    },

    created() {
        window.addEventListener('resize', this.windowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.windowResize);
    },
    methods: {
        windowResize: debounce(function () {
            this.windowWidth = window.innerWidth;
        }, 5000),
        update(name) {
            if (!name) {
                name = '';
            }
            this.$emit('update:name', name);
        },
        onFilterChange(value) {
            this.$emit('update:pagination');
            this.$emit('update:name', '');
            this.$emit('update:author', '');
            this.$emit('update:tags', []);

            this.debouncedInput = '';
            this.filterValue = value.value || value;
            this.$emit('update:filter', value.main);
        },
        onAuthorsChange(authors) {
            this.$emit('update:author', authors);
        },
        onTagsChange(tags) {
            this.$emit('update:tags', tags);
        },
    },
};
</script>

<style lang="scss" scoped>
.search-section__search {
    position: relative;

    .input--search {
        button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            border-radius: 0 4px 4px 0;
            text-transform: capitalize;
            outline: 0;
        }

        &.loading {
            opacity: 0.1;
        }
    }

    .button {
        position: relative;

        border-radius: 0 4px 4px 0;

        @media (max-width: $sm-down) {
            background-color: #edf0f3;
        }
    }
    .sorting__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>

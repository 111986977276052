<template>
    <div class="find-tag">
        <ItemSelector
            :items="tags"
            input-placeholder="Search for Genres"
            icon="tag-o"
            :size="inputSize"
            :total-pages="totalPages"
            :allow-create="allowCreate"
            @search="loadTagsOnChange"
            @add="addNew"
            @scroll="loadTagsOnScroll"
        />
        <div v-if="showTags && activeTags" class="find-tag__tags">
            <ItemsVisualizer
                :default-items="activeTags"
                :removable="true"
                :clickable="true"
                @update:remove="remove"
            />
        </div>
    </div>
</template>

<script>
import isEqual from 'lodash.isequal';
import { mapActions, mapGetters } from 'vuex';

import ItemSelector from '@/components/common/ItemSelector.vue';
import ItemsVisualizer from '@/components/common/ItemsVisualizer.vue';

import { TagsResource } from './../../services/tags';

export default {
    name: 'TagPicker',
    components: { ItemSelector, ItemsVisualizer },
    props: {
        defaultTags: {
            type: Array,
            default: function () {
                return [];
            },
        },
        allowCreate: {
            type: Boolean,
            default: false,
        },
        inputSize: {
            type: String,
            default: '',
        },
        showTags: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            page: 1,
            activeTags: [],
            newTags: [],
            tags: [],
            tagsCount: 0,
        };
    },
    computed: {
        ...mapGetters('tags', {
            loading: 'loading',
        }),
        totalPages() {
            return Math.ceil(this.tagsCount / 20) || 1;
        },
    },
    watch: {
        defaultTags(tags, oldTags) {
            this.activeTags = JSON.parse(JSON.stringify(this.defaultTags));
            if (!isEqual(tags, oldTags)) {
                this.loadInitial(tags);
            }
        },
    },
    mounted() {
        this.loadInitial(this.defaultTags);
    },
    methods: {
        ...mapActions('tags', {
            loadTags: 'load',
        }),
        addNew(tag) {
            if (tag) {
                this.activeTags.push(tag);

                if (!tag.id) {
                    this.newTags = [...this.newTags, tag];
                }

                this.$emit('change', this.activeTags);
            }
        },
        remove(tags) {
            this.$emit('change', tags);
        },
        loadTagsOnChange(name) {
            this.loadTags({ name: name, rowsPerPage: 20, page: 1 }).then(
                (res) => {
                    this.tags = res.rows;
                    this.tagsCount = res.count;
                }
            );
        },
        loadTagsOnScroll(name, page) {
            this.loadTags({
                name: name,
                rowsPerPage: 20,
                page: page < this.totalPages ? page : this.totalPages,
            }).then((res) => {
                this.tags.push(...res.rows);
            });
        },
        loadInitial(ids) {
            if (ids && ids.length > 0) {
                if (typeof ids[0] === 'object') {
                    this.activeTags = ids;
                } else {
                    TagsResource.get({
                        ids: ids,
                    }).then((response) => {
                        this.activeTags = response.data.rows.concat(
                            this.newTags
                        );
                    });
                }
            }
        },
    },
};
</script>

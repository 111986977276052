// Babel 6 loosing constructor in compilation process
// we have to hack it with help of 'ExtendableBuiltin'
// https://github.com/babel/babel/issues/3083

function ExtendableBuiltin(cls) {
    function ExtendableBuiltin() {
        cls.apply(this, arguments);
    }

    ExtendableBuiltin.prototype = Object.create(cls.prototype);
    Object.setPrototypeOf(ExtendableBuiltin, cls);

    return ExtendableBuiltin;
}

export default ExtendableBuiltin(Error);

import BaseError from './BaseError';

export default class ApiError extends BaseError {
    constructor(message, apiCode) {
        super(message);
        this.apiCode = apiCode;
        this.name = this.constructor.name;
        this.message = message;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
    }
}

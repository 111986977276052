<template>
  <div class="find-tag__controls" :class="[inputSize]">
    <div
      class="find-tag__input input input--search input--rounded input--icon"
      :class="[inputSize]"
    >
      <!--TODO add debounce-->
      <input
        v-model="searchString"
        :placeholder="inputPlaceholder"
        type="text"
        class="new-tag js-input-field"
        :maxlength="maxItemNameLength"
        @keyup="inputOnKey($event, searchString)"
        @keydown.esc.prevent="hideDropdown()"
      />

      <div
        class="dropdown-outer"
        :class="{
          open: openDrodown,
          empty: items.length == 0 && allowCreate == false,
        }"
      >
        <ul
          v-if="items.length > 0"
          v-infinite-scroll="loadMoreItemsOnScroll"
          class="dropdown"
          infinite-scroll-disabled="scrollDisabled"
          infinite-scroll-distance="20"
        >
          <li v-for="(item, i) in items" :key="`selector_item_${i}`">
            <a
              href="#"
              @keydown.40.prevent
              @keyup.40.prevent.stop="focusOnNext($event)"
              @keydown.38.prevent.stop="focusOnPrev($event)"
              @keydown.esc.prevent="hideDropdown()"
              @click.prevent="addNew(item)"
              v-html="
                higlightItemName(
                  item.name
                    ? item.name.replace('YlandsGenre_', '')
                    : item.ylands_username
                )
              "
            ></a>
          </li>

          <li v-if="loading">
            <div>Loading...</div>
          </li>

          <li
            v-if="
              items.length === 0 &&
              !loading &&
              !allowCreateItemMenuItemVisible &&
              !validateError
            "
          >
            <a
              href="#"
              class="disabled"
              @click.prevent.stop
              @keydown.esc.prevent="hideDropdown()"
            >
              <BanIcon class="find-tag__icon-add icon-no-tags" />
              No match
            </a>
          </li>

          <template v-if="allowCreate">
            <li
              v-if="
                allowCreateItemMenuItemVisible &&
                !itemInSelectionByName(searchString) &&
                !validateError
              "
            >
              <a
                href="#"
                @keyup.40.prevent.stop="focusOnNext($event)"
                @keydown.38.prevent.stop="focusOnPrev($event)"
                @keydown.esc.prevent="hideDropdown()"
                @click.prevent.stop="createItem(searchString)"
              >
                <DocumentAddIcon class="find-tag__icon-add icon-add-tag" />
                Add new item
              </a>
            </li>

            <li v-if="validateError">
              <a
                href="#"
                class="disabled"
                @click.prevent.stop
                @keydown.esc.prevent="hideDropdown()"
              >
                <BanIcon class="find-tag__icon-add icon-no-tags" />
                {{ validateError }}
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll';

const MAX_ITEM_NAME_LENGTH = 50;
const MIN_ITEM_NAME_LENGTH = 3;

export default {
  name: 'BaseSelector',
  directives: { infiniteScroll },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    inputPlaceholder: {
      type: String,
      default: 'Type a tag',
    },
    icon: {
      type: String,
      default: 'tag-o',
    },
    size: {
      type: String,
      default: '',
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 10,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    inputRegexp: {
      type: RegExp,
      default: () => {
        return /^[a-zA-Z0-9\s]*$/;
      },
    },
  },
  data() {
    return {
      searchString: '',
      openDrodown: false,
      itemIds: [],
      itemsArray: [],
      page: 1,
      isOpen: false,
      maxItemNameLength: MAX_ITEM_NAME_LENGTH,
      validateError: null,
    };
  },
  computed: {
    allowCreateItemMenuItemVisible() {
      if (!this.allowCreate || this.items.length >= this.maxItems) {
        return false;
      }

      const itemInputName = this.searchString.toLowerCase();
      const itemInputNameLength =
        itemInputName.length >= MIN_ITEM_NAME_LENGTH &&
        itemInputName.length <= MAX_ITEM_NAME_LENGTH;
      const itemNameConsistLetterOrNum =
        /^[\w .]+$/.test(itemInputName) && /[0-9a-zA-Z]/.test(itemInputName);
      let alreadyChosen = false;

      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].name === itemInputName) {
          alreadyChosen = true;
          break;
        }
      }
      return (
        itemInputNameLength && itemNameConsistLetterOrNum && !alreadyChosen
      );
    },
    scrollDisabled() {
      return this.page >= this.totalPages;
    },
    inputSize() {
      return this.size ? `input--${this.size}` : '';
    },
    loading() {
      return this.$store.state.authors.loading;
    },
  },
  watch: {
    searchString() {
      if (this.searchString.length < 5) {
        this.$emit('search', this.searchString, this.page);
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.hideDropdownClick);
  },
  destroyed() {
    window.removeEventListener('click', this.hideDropdownClick);
  },
  methods: {
    itemInSelectionByName(tagName) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].name.toLowerCase() === tagName.toLowerCase()) {
          return true;
        }
      }
      return false;
    },
    addNew(item) {
      if (item) {
        this.$emit('add', item);
      }
      this.searchString = '';
      this.$el.querySelector('.js-input-field').focus();
    },
    createItem(text) {
      this.addNew({ name: text.toLowerCase() }, true);
    },
    inputOnKey(e, val) {
      // if ESC
      if (e.keyCode === 27) {
        e.target.blur();
        return this.hideDropdown();
      }
      // if arrow key down
      if (e.keyCode === 40) {
        if (!val.length && !this.openDrodown) {
          this.page = 1;

          this.$emit('search', val, this.page);
          this.openDrodown = true;
          return;
        }

        if (this.openDrodown) {
          return this.focusOnDropdown(e);
        }
      }

      // if ENTER
      if (e.keyCode === 13) {
        if (!val.length) {
          this.openDrodown = true;
          return;
        }
        if (this.openDrodown) {
          return (this.openDrodown = false);
        }
        return e.preventDefault();
      }

      if (this.allowCreate) {
        this.validateInput(val);
      }

      if (val.length > 0) {
        this.openDrodown = true;
        this.validateInput(val);
        this.page = 1;

        this.$emit('search', val, this.page);
      } else {
        this.openDrodown = false;
      }
    },
    focusOnDropdown(e) {
      e.target.parentNode.querySelector('ul li a').focus();
    },
    focusOnNext(e) {
      const nextSibling = e.target.parentNode.nextElementSibling;
      if (nextSibling !== null) {
        nextSibling.firstChild.focus();
      }
    },
    focusOnPrev(e) {
      const prevSibling = e.target.parentNode.previousElementSibling;
      if (prevSibling !== null) {
        prevSibling.firstChild.focus();
      }
    },
    hideDropdown() {
      if (!this.openDrodown) {
        return;
      }
      this.openDrodown = false;
    },
    hideDropdownClick(e) {
      const input = this.$el.querySelector('.new-tag');
      if (this.openDrodown && e.target != input) {
        this.openDrodown = false;
      }
    },
    higlightItemName(name) {
      const inputVal = this.searchString.toLowerCase();

      if (inputVal.length < 1) return name;

      const reg = new RegExp('(' + inputVal + ')', 'i');
      return name.replace(reg, `<span class="highlited">$1</span>`);
    },
    loadMoreItemsOnScroll() {
      this.page++;
      this.$emit('scroll', this.searchString, this.page);
    },
    validateInput(val) {
      const isProperLength =
        val.length >= MIN_ITEM_NAME_LENGTH &&
        val.length <= MAX_ITEM_NAME_LENGTH;

      if (!isProperLength) return;

      if (!this.validateInputCharacters(val)) {
        this.validateError = 'Wrong format';
        return;
      }

      if (!this.validateInputMaxLength(val)) {
        this.validateError = 'Name is too long';
        return;
      }

      if (this.itemInSelectionByName(val)) {
        this.validateError = 'Already selected';
        return;
      }

      if (this.validateError) this.validateError = null;
    },
    validateInputCharacters(val) {
      return this.inputRegexp.test(val);
    },
    validateInputMaxLength(val) {
      return val.length <= MAX_ITEM_NAME_LENGTH;
    },
  },
};
</script>

<template>
    <img :src="src" :alt="alt" :title="title" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Webp',
    props: {
        webp: {
            type: String,
            default: '',
        },
        fallback: {
            type: String,
            default: '',
        },
        alt: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters('settings', {
            webpSupports: 'webp',
        }),
        src() {
            if (this.webpSupports && this.webp) {
                return require(`@/assets/img/${this.webp}`);
            } else {
                return require(`@/assets/img/${this.fallback}`);
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

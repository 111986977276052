import BaseError from './BaseError';

export default class Unauthorized extends BaseError {
    constructor(message) {
        super(message);
        this.message = message;
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(this.message).stack;
        }
    }
}

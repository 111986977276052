'use strict';
import { TagsResource } from '@/services/tags';
import * as types from './../mutation-types';

const state = {
    tags: {
        rows: [],
    },
    popularTags: [],
    loading: false,
};

const getters = {
    getTags(state) {
        return state.tags.rows;
    },
    getTagsCount(state) {
        return state.tags.count;
    },
    loading: state => {
        return state.loading;
    },
};

const actions = {
    load({ commit }, params) {
        commit(types.LOADING, true);
        return TagsResource.get(params)
            .then(response => {
                commit(types.RECEIVE_TAGS, {
                    data: response.data,
                });
                commit(types.LOADING, false);
                return response.data;
            })
            .catch(() => {
                commit(types.RECEIVE_TAGS, {
                    data: {
                        rows: [],
                    },
                });
                commit(types.LOADING, false);
                return { rows: [], count: 0 };
                // eslint-disable-next-line vue/script-indent
            });
    },
    loadPopularTags({ commit, state }) {
        if (state.popularTags.length) {
            return Promise.resolve(state.popularTags);
        }

        return TagsResource.get({
            order_direction: ['desc'],
            offset: 0,
            limit: 3,
        }).then(response => {
            const tags = response.data.rows;
            commit('setMostPopularTags', tags);
            return tags;
            // eslint-disable-next-line vue/script-indent
        });
    },
};

const mutations = {
    [types.RECEIVE_TAGS](state, tags) {
        state.tags = tags;
    },
    [types.LOADING](state, val) {
        state.loading = val;
    },
    // TODO: add constant for this type
    setMostPopularTags(state, list) {
        state.popularTags = list;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

<template>
    <section class="search-section">
        <div class="search-section__top-line">
            <SearchByName
                :loading="loading"
                :name="name"
                :active-type="type"
                :default-items="defaultTags"
                :authors-array="author"
                :sort-by="sortBy"
                class="search-section__name"
                @update:name="onNameChanged"
                @update:author="onAuthorsChange"
                @update:tags="onTagsChange"
                @update:pagination="paginationProps"
            />

            <SortBy
                :loading="loading"
                :sort-by="sortBy"
                class="search-section__sort"
                @update:sortBy="changeSortBy"
            ></SortBy>
        </div>
        <template v-if="selectedTagsNotEmpty">
            <!--SEARCH RESULTS START-->
            <div class="search-section__section">
                <ItemsVisualizer
                    :default-items="defaultTags"
                    :authors-array="author"
                    :removable="true"
                    :clickable="false"
                    @remove:tag="onTagsChange"
                    @remove:author="onAuthorsChange"
                />
            </div>
            <!--SEARCH RESULTS END-->
        </template>
    </section>
</template>

<script>
import { TagsResource } from '@/services/tags';

import SearchByName from './SearchByName.vue';
import ItemsVisualizer from '@/components/common/ItemsVisualizer.vue';

import SortBy from '@/components/assetsTable/SortByWidget.vue';

export default {
    components: {
        SearchByName,
        ItemsVisualizer,
        SortBy,
    },
    props: {
        name: { type: String, default: '' },
        author: { type: String, default: '' },
        tags: { type: Array, default: () => [] },
        type: { type: [Object, String, Number], default: null },
        loading: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            defaultTags: [],
        };
    },
    computed: {
        selectedTagsNotEmpty() {
            return this.tags.length > 0 || !!this.author;
        },
    },
    watch: {
        tags() {
            this.loadTags();
        },
    },
    created() {
        this.loadTags();
    },
    methods: {
        onNameChanged(val) {
            this.$emit('update:name', val);
        },
        onAuthorsChange(authors) {
            this.$emit('update:author', authors);
        },
        onTagsChange(tags) {
            this.$emit('update:tags', tags);
        },
        paginationProps() {
            this.$emit('update:pagination');
        },
        // onTypeChanged(val) {
        //     this.$emit('update:type', val);
        // },
        loadTags() {
            if (!this.tags.length) {
                this.defaultTags = [];
                return;
            }

            TagsResource.get({
                ids: this.tags,
            }).then((res) => {
                this.defaultTags = res.data.rows;
            });
        },
        changeSortBy(sortBy) {
            this.$emit('update:sortBy', sortBy);
        },
    },
};
</script>

<style lang="scss" scoped>
.reset-button {
    i {
        margin-right: 0.5em;
    }
}

.search-section {
    max-width: 1659px;
    margin: 0 auto;
    width: 100%;
    padding: 0 0 2em;
    font-size: 1rem;
    border-bottom: 1px solid $fake-white;
    padding: 2.3em 0;

    @media (max-width: $sm-down) {
        padding: 1.5em 0;
    }
}

.search-section__top-line {
    display: flex;
    align-items: center;
    flex-direction: row;

    @media (max-width: $sm-down) {
        flex-direction: column;
        align-items: flex-start;
    }

    .search-section__name {
        flex: 2 1 80%;
        margin-right: 15px;

        @media (max-width: $sm-down) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
            flex-basis: auto; //odd Safari/iOS bug with height being too big
        }
    }
    .search-section__sort {
        flex: 1 0 auto;
    }
}

.search-section__collapse {
    overflow: hidden;
    max-height: 0;
    transition: all $transition-values;

    &.open {
        max-height: 182px;
    }

    &.transitioned {
        overflow: visible;
    }
}

.search-section__inputs {
    display: flex;
    align-items: flex-end;
}

.search-section__categories {
    // for future need
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 1rem;
}

///////////////////////
// GENERIC CLASSES

.search-section__section {
    margin-top: 2rem;

    @media (max-width: $sm-down) {
        padding-top: 1.5rem;
        border-top: 1px solid #e5e5e5;
    }
}

.search-section__divider-xl {
    position: relative;
    width: 100%;
    height: 50px;
    clear: both;
    &:after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: $fake-white;
        left: 0;
        top: 50%;
        width: 100%;
    }
}

.search-section__heading {
    margin: 0 0 rem(20);
    font-size: 1rem;
    text-transform: uppercase;
    color: #b2b2b2;
}

///////////////////////
// BUTTONS

.search-section__button-link {
    font-size: 1.2rem;
    text-transform: capitalize;
    outline: none;

    @media (max-width: $sm-down) {
        font-size: 0.9rem;
    }

    @media (max-width: $xs-down) {
        font-size: 0.75rem;
    }

    &:hover,
    &:focus {
        span {
            border-color: transparent;
        }
    }

    //&:not(:last-child) {
    //  margin-right: 1em;
    //}

    span {
        font-family: $istok-font;
        font-weight: 700;
        border-bottom: 1px solid;
        vertical-align: middle;
    }

    i {
        position: relative;
        top: 0.1em;
        font-size: 1.1em;
        margin-right: 0.25em;
    }

    i.icon-big {
        font-size: 1.5em;
    }
}

.search-section__button-tab {
    padding: 0 0.5em;
    font-size: 1.2rem;
    text-transform: capitalize;
    text-align: center;
    border: 1px solid;
    border-radius: 0.5em;
    outline: none;

    max-width: 280px;
    line-height: 47px;

    flex: 1;

    &:hover,
    &:focus {
        background-color: lighten($blue-main, 10%);
        color: #fff;
    }

    @media (max-width: $md-down) {
        max-width: 230px;
    }

    &:not(:last-child) {
        margin-right: rem(10);
    }

    &.active {
        background-color: $blue-main;
        color: #fff;

        &:hover,
        &:focus {
            background-color: lighten($blue-main, 10%);
            color: #fff;
        }
    }

    i {
        font-size: 1.1em;
        margin-right: 0.25em;
        transition: none;
    }
}

.search-section__result-button {
    margin-left: auto;
    font-size: rem(15);
    padding: 0 10px;
    line-height: 49px;
    min-width: 185px;
    border-radius: 0.25em;
}

.search-section__reset-button {
    margin-left: auto;
    //line-height: 47px; // size of lg input

    span {
        display: inline-block;
        min-width: 104px;
        text-align: center;
    }

    i {
        margin-right: 0.6em; // to align with toggle button
    }
}
</style>

<template>
    <div class="account">
        <div class="account__avatar">
            <ProfileAvatar :username="username" />
        </div>
        <div class="account__name_and_icon">
            <h2 :title="`Username: ${username}`" class="account__header">
                {{ username }}
            </h2>
            <span
                v-if="profile && profile.verified"
                title="Verified author"
                class="account__icon"
            >
                <VerifiedBadgeIcon
                    class="account__verified-icon"
                    title="Verified Author"
                />
                <h3 v-if="profile.socialAccount" class="account__verified-icon">
                    {{ profile.socialAccount }}
                    @teamylands
                </h3>
                <h3 class="account__author-social">
                    {{ profile.socialAccount }}
                    @teamylands
                </h3>
            </span>
        </div>
        <div class="account__info">
            <ul v-if="profile" class="account__list">
                <li>
                    <strong>Games:</strong>
                    {{ totalGames }}
                </li>
                <li>
                    <strong>Creations:</strong>
                    {{ totalCreations }}
                </li>
                <li>
                    <strong>Watched by:</strong>
                    {{ watchedBy }}
                </li>
                <li>
                    <strong>Subscribers count:</strong>
                    {{ totalSubscribers }}
                </li>
                <li>
                    <strong>Overall rating:</strong>
                    {{ overallRating }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import EventBus from '@/EventBus';
import { AuthorsResource } from '@/services/authors';
import ProfileAvatar from '../common/ProfileAvatar.vue';

import VerifiedBadgeIcon from 'Sprites/verified_badge.svg?inline';

export default {
    components: {
        ProfileAvatar,
        VerifiedBadgeIcon,
    },
    data() {
        return {
            profile: {},
            profileCreationsCount: 0,
            profileSubscribersCount: 0,
            profileGamesCount: 0,
            overallRatingCount: 0,
        };
    },
    computed: {
        username() {
            return this.profile.ylands_username || this.profile.username;
        },
        overallRating() {
            const overallRating = this.overallRatingCount;

            if (overallRating <= 0) {
                return 'No rating';
            } else {
                return overallRating;
            }
        },
        totalGames() {
            // we dont need to check if undefined, cause this value already predefined in component
            const totalGames = this.profileGamesCount;

            if (totalGames <= 0) {
                return 'None';
            } else {
                return `${totalGames} game${totalGames !== 1 ? 's' : ''}`;
            }
        },
        // totalGames(){const totalGames =},
        totalCreations() {
            // we dont need to check if undefined, cause this value already predefined in component
            const totalCreations = this.profileCreationsCount;
            return `${totalCreations} creation${
                totalCreations !== 1 ? 's' : ''
            }`;
        },
        watchedBy() {
            const followerCount = this.profile.follower_count;

            if (followerCount !== undefined && followerCount > 0) {
                return `${followerCount} user${followerCount !== 1 ? 's' : ''}`;
            } else {
                return 'nobody';
            }
        },
        totalSubscribers() {
            // we dont need to check if undefined, cause this value already predefined in component
            const totalSubscribers = this.profileSubscribersCount;

            if (totalSubscribers <= 0) {
                return 'nobody';
            } else {
                return `${totalSubscribers} user${
                    totalSubscribers !== 1 ? 's' : ''
                }`;
            }
        },
    },
    created() {
        this.loadProfile();

        EventBus.$on('authorAssetsLoaded', (assets) => {
            this.profileCreationsCount = assets.count;
            this.profileSubscribersCount = assets.rows.reduce((acc, row) => {
                return acc + row.subscribers_count;
            }, 0);
            this.profileGamesCount = assets.rows.reduce((acc, creation) => {
                let games = 0;
                if (creation.content_type === 3) {
                    games += 1;
                }
                return acc + games;
            }, 0);
            this.overallRatingCount = assets.rows.reduce((acc, creation) => {
                return (
                    Math.round(
                        ((acc + creation.rating) / assets.rows.length) * 100
                    ) / 100
                );
            }, 0);
        });
    },
    methods: {
        async loadProfile() {
            let response = await AuthorsResource.profile({
                id: this.$route.params.id,
            });
            this.profile = response.body;
        },
    },
};
</script>
<style scoped lang="scss">
@import './profile.scss';
</style>

'use strict';

class webp {
    constructor() {
        this.webpSupport = null;
    }

    isSupported() {
        if (this.webpSupport !== null) {
            return Promise.resolve(this.webpSupport);
        }
        return new Promise((res) => {
            const webP = new Image();
            webP.src =
                'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
            webP.onload = webP.onerror = () => {
                res(webP.height === 2);
            };
        });
    }
}

/**
 * @singleton
 */
export default new webp();

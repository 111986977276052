<template>
    <Modal v-if="showModal" size="sm" @close="onNo()">
        <div slot="body" class="modal-content">
            <h2 class="heading heading--lg">Session expired</h2>
            <p>
                To protect your data, a browser session expires after about 60
                minutes of inactivity
            </p>
            <div class="divider divider--40"></div>

            <div class="text--center">
                <button
                    class="modal__button-yesno button button--with-icon"
                    @click="logout"
                >
                    <span>Ok</span>
                    <i class="icon-no">
                        <NoIcon />
                    </i>
                </button>
                <button
                    class="modal__button-yesno button button--with-icon active"
                    @click="login"
                >
                    Login
                    <i class="icon-finish">
                        <TickIcon />
                    </i>
                </button>
            </div>
        </div>
    </Modal>
</template>

<script>
//TODO maybe to this in a more sophisticated way,  without propting the user at all?
import Modal from './Modal.vue';
import EventBus from './../../../EventBus';

import NoIcon from 'Sprites/no.svg?inline';
import TickIcon from 'Sprites/tick.svg?inline';

let sessionExpiredDialogVisible = false;

export default {
    components: {
        Modal,
        NoIcon,
        TickIcon,
    },
    data() {
        return {
            showModal: false,
        };
    },
    computed: {},
    mounted() {
        EventBus.$on('session-expired', () => {
            if (sessionExpiredDialogVisible) {
                return;
            }
            sessionExpiredDialogVisible = true;
            this.init().then(() => {
                sessionExpiredDialogVisible = false;
            });
        });
    },
    methods: {
        logout() {
            window.location.href = '/';
            this.close();
        },
        login() {
            try {
                window.location.href = '/api/auth/login';
            } catch (e) {
                console.error('Unable to re-login!', e);
            }
            this.close();
        },
        close() {
            this.$emit('dialogClose');
        },
        init() {
            this.showModal = true;

            return new Promise(resolve => {
                this.$once('dialogClose', () => {
                    this.showModal = false;
                    resolve();
                });
            });
        },
    },
};
</script>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import webp from './lib/webp';
import {
    mobileAndTabletCheck,
    iOSCheck,
    operaTouchCheck,
} from './lib/mobileRecognition';

//Modules Import
import VueLazyload from 'vue-lazyload';
import MainHeader from '@bistudio/ylands-vue-components/src/components/Header';

//Components Import

import BackToLink from './components/common/BackToLink.vue';
import SessionDialog from './components/common/modal/SessionDialog.vue';
import Notifications from './components/notification/Notifications.vue';

//CSS Import
import 'sanitize.css';

//Modules Use
Vue.use(VueLazyload, {
    lazyComponent: true,
});

//Components Use
Vue.component('BackToLink', BackToLink);
Vue.component('Notifications', Notifications);
Vue.component('MainHeader', MainHeader);
Vue.component('SessionDialog', SessionDialog);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

const init = async () => {
    let webpSupport = await webp.isSupported();
    if (webpSupport) {
        store.commit('settings/webp', webpSupport);
        document.body.classList.add('webp');
    } else {
        document.body.classList.add('no-webp');
    }
    let isMobile = await mobileAndTabletCheck();
    if (isMobile) {
        store.commit('settings/mobileAndTabletChecked', isMobile);
    }
    let isIos = await iOSCheck();
    if (isIos) {
        store.commit('settings/iOSChecked', isIos);
        let isOperaTouch = operaTouchCheck();
        if (isOperaTouch) {
            store.commit('settings/operaTouchChecked', isOperaTouch);
        }
    }
};
init();

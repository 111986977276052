<template>
  <div class="find-tag">
    <ItemSelector
      :items="authors"
      input-placeholder="Search for Authors"
      icon="user-o"
      :size="inputSize"
      :input-regexp="/^[a-zA-Z0-9 .'_-]*$/"
      @search="load"
      @add="addNew"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ItemSelector from '@/components/common/ItemSelector.vue';

export default {
  name: 'AuthorPicker',
  components: { ItemSelector },
  props: {
    defaultTags: {
      type: [Array, String],
      default: () => [],
    },
    inputSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      page: 1,
      activeTags: [],
    };
  },
  computed: {
    ...mapGetters('authors', {
      authors: 'getAuthors',
      loading: 'loading',
    }),
  },
  methods: {
    ...mapActions('authors', {
      loadAuthors: 'load',
    }),
    addNew(tag) {
      if (tag) {
        this.activeTags = [tag];
        this.$emit('change', [tag]);
      }
    },
    load(name) {
      this.loadAuthors({
        ylands_username: name,
        offset: 0,
        limit: 20,
      });
    },
  },
};
</script>
